import React from 'react'

import './coming.css'

function Coming() {

   
  return (
    <div className='coming'>
        <h1>Coming Soon!!</h1>
    </div>
  )
}

export default Coming;